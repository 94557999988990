<template>
  <v-sheet  elevation="4" class="facility-card" >
    <h2 style="margin-bottom: 40px;">{{$t('facility.facilityTitle')}}</h2>
    <div v-if="pccIntegration" class="facility-card">
      <label>{{$t('integrations.pointClickCare')}}</label>
      <div class="margin__bottom--10">
        <b-form-select v-model="selected" :options="options"></b-form-select>
      </div>
      <v-btn block color="primary" :loading="importing"  style="color: white" @click="triggerImport()" >
        {{importing ? $t('buttons.stillImporting') : $t('buttons.import')}}
      </v-btn>
      <p v-if="importing">{{$t('integrations.importStarted')}}</p>
    </div>
    <div class="facility-card" v-if="items.length > 0 && !pageLoading">
      <b-table bordered striped hover :items="items" :fields="fields"  >
      </b-table>
    </div>
    <div v-else-if="pageLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <h4>No Facility Structure Created for Customer</h4>
      <h4>{{currentCustomer}}</h4>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import TreeModel from 'tree-model-improved'
import { combineLocation } from '@/utils/nameArrayShortener'
import localforage from 'localforage'

const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  name: 'Facility',
  data () {
    return {
      items: [],
      fields: [
        { key: 'Location', label: this.$t('tables.facility.location'), class: 'text-left' },
        { key: 'Sublocations', label: this.$t('tables.facility.sublocations') },
        { key: 'Devices', label: this.$t('tables.facility.devices') }
      ],
      importing: false,
      options: [
        { value: null, text: 'Please select a unit type' },
        { value: 'units', text: 'Units' },
        { value: 'floors', text: 'Floors' }
      ],
      selected: null,
      pageLoading: false
    }
  },
  computed: {
    ...mapGetters({
      getContainers: 'containers/getContainers',
      myIntegrations: 'login/myIntegrations',
      myIds: 'login/getMyIds',
      getImportingState: 'containers/getImportingState',
      getLatestOtherEventMessage: 'devices/getLatestOtherEventMessage',
      getCurrentCustomer: 'login/getCurrentCustomer',
      myCustomers: 'login/myCustomers'
    }),
    pccIntegration: function () {
      return this.myIntegrations?.type === 'AMPLIFY'
    },
    currentCustomer: function () {
      return this.myCustomers.filter(cus => cus.id === this.getCurrentCustomer[0])?.[0]?.name
    }
  },
  methods: {
    ...mapActions({
      fetchContainers: 'containers/fetchContainers',
      doImportAmplify: 'containers/doImportAmplify',
      resetImportState: 'containers/resetImportState',
      fetchMyInfo: 'login/fetchMyInfo',
      checkForIntegrations: 'login/checkForIntegrations'
    }),
    async triggerImport () {
      this.importing = true
      try {
        const selectedType = this.selected || 'floors'
        localforage.setItem(this.myIds?.CUSTOMER?.[0] + '/Amplify_Import', true)
        await this.doImportAmplify(selectedType)
      } catch (err) {
        this.importing = false
        console.log(err)
      }
    },
    uploadFile () {
      console.log('success')
    }
  },
  async mounted () {
    this.pageLoading = true
    await this.fetchContainers()
    const isLoading = await localforage.getItem(this.myIds?.CUSTOMER?.[0] + '/Amplify_Import') || false
    this.importing = isLoading
  },
  watch: {
    getContainers (newVal, oldVal) {
      if (newVal) {
        this.items = []

        treeModel.parse(cloneDeep(newVal)).walk(node => {
          const location = combineLocation(node?.model?.fullyQualifiedName, ' / ', 24) || node?.name
          const newContainer = {
            Location: location,
            Sublocations: node.model?.children?.length || 0,
            Devices: node.model?.devices?.length || 0
          }
          this.items.push(newContainer)
        })
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: 'base'
        })

        this.items.sort(function (a, b) {
          return collator.compare(a.Location, b.Location)
        })

        this.pageLoading = false
      }
    },
    getLatestOtherEventMessage: async function (newVal, oldVal) {
      if (newVal?.meta?.event?.did === 'import-amplify-org') {
        this.importing = false
        this.resetImportState()
        if (newVal?.meta?.event?.status === 'SUCCESS') await this.fetchContainers()
      }
    },
    getCurrentCustomer: async function (newVal, oldVal) {
      if (newVal[0] !== oldVal[0]) {
        this.checkForIntegrations(false)
      }
    }
  }
}
</script>
