
export function combineLocation (strArray, stringDelim, maxStrLen = 14) {
  let justString = ''
  if (strArray.length > 1) {
    // Block for Array that is too long
    for (let i = 0; i < strArray.length; i++) {
      if (i === 0 || i === strArray.length - 2) {
        // Push first and second to last with delimiter
        justString += (shorten(strArray[i], maxStrLen) + stringDelim)
      } else if (i === strArray.length - 1) {
        // Avoid Delim on Final Array Item
        justString += (shorten(strArray[i], maxStrLen))
      }
    }
  } else {
    // Block for Array that doesn't need shortening
    for (let i = 0; i < strArray.length; i++) {
      if (i === strArray.length - 1) {
        // Avoid Delim on Final Array Item
        justString += (shorten(strArray[i], maxStrLen))
        continue
      }
      justString += (shorten(strArray[i], maxStrLen) + stringDelim)
    }
  }
  return justString
}

// Shorten Single Item string in Some Cases
function shorten (str, num) {
  let answer = str
  if (str.length > num) {
    answer = str.substr(0, num) + '..'
  }
  return answer
}
