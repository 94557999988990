<template>
  <div>
    <Facility />
  </div>
</template>

<script>
import Facility from '@/components/facility/Facility'
export default {
  components: {
    Facility
  }
}
</script>
